import React, { useCallback, useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import Modal from 'react-modal';
import ReactAudioPlayer from 'react-audio-player';
import { Spinner, useToast } from '@chakra-ui/react'
import { Formik, Form, Field, replace } from 'formik';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { deleteConversation, getSingleConversation, getUserMetaReport, getUserScore, replyToConversation } from '../../../services/dataApi/dataProvider';
import { formatTextWithLinks, headerProvider, showErrorMessage } from '../../../common/CommonFunctions';




const SingleStudyUser = () => {
  const Navigate = useNavigate();
  const { conversationId } = useParams();  // Extract 'id' from the URL
  const location = useLocation();
  const [showModal, setShowModal] = useState(false)
  const [loader, setLoader] = useState(false)
  const [modalType, setModalType] = useState('')
  const [conversation, setConversation] = useState()
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [metabaseReportData, setMetabaseReportData] = useState()
  const [userMetaScore, setUserMetaScore] = useState()
  const [report, setReport] = useState({})
  const [replyToUser, setReplyToUser] = useState({
    replyMessage: ''
  })

  const toast = useToast()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setReplyToUser((inputData) => ({ ...inputData, [name]: value }))
  }
  const showConvoReport = () => {
    if (showReport) {
      const userReport = conversation?.messages.filter((item) => {
        return item.reportType
      })
      if (userReport) {
        setReport(userReport[0])
      }
    }
  }
  const handleDeleteConversation = async (conversation_id) => {
    const headers = headerProvider();
    const requestOptions = {
      method: "DELETE",
      headers,
    };
    const response = await deleteConversation(conversation?.id, requestOptions);
    if (response && response.success) {
      showErrorMessage(
        "Deleted",
        "Conversation has been deleted successfully.",
        "success",
        toast
      );
      Navigate('/studyUsers', { replace: true });
    } else {
      showErrorMessage(
        response ? "Failed" : "Server Error",
        response ? response?.response?.errors[0] : "The server is not responding. Please try later!",
        "error",
        toast
      );
      handleDeleteAlert();
    }
  }
  const openModal = () => {
    setModalType('reply')
    setShowModal(!showModal)
  }

  const handleDeleteAlert = () => {
    setModalType('delete')
    setShowModal(!showModal);
  }
  const handleMetabaseReport = () => {
    setModalType('metabase')
    setShowModal(!showModal);
  }

  const handleReportAlert = () => {
    setModalType('sdReport')
    showConvoReport()
    setShowModal(!showModal)
  }

  const RenderData = ({ metabaseReportData, userMetaScore }) => {
    return (
      <>
        <div>
          {Object.entries(metabaseReportData).map(([category, values]) => (
            <div key={category} style={{ marginBottom: "20px" }}>
              <h2 className='text-base mb-2'><strong>{category}</strong></h2>
              <ul>
                {Object.entries(values).map(([key, value]) => (
                  <li key={key}>
                    {key}: <strong>{value.toString() === 'true' ? 'Visited' : 'Not Visited'}</strong>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div>
          <h2>User Scores</h2>
          <ul>
            {userMetaScore?.response?.map((item, index) => (
              <li key={index}>
                {Object.entries(item).map(([key, value]) => (
                  <div key={key}>
                    <strong>{key}:</strong> {value !== null ? value.toString() : "N/A"}
                  </div>
                ))}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  const getSingleConvoData = useCallback(async () => {
    try {
      setLoader(true)
      const headers = headerProvider()
      const response = await getSingleConversation(conversationId, headers)
      setConversation(response.response)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }, [])

  const getMetabaseReport = useCallback(async () => {
    try {
      const userId = location.state
      const headers = headerProvider()
      setLoader(true)
      const response = await getUserMetaReport(headers, userId)
      const responseScore = await getUserScore(headers, userId)
      setUserMetaScore(responseScore)
      setMetabaseReportData(response)
      setLoader(false)
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }, [location.state])

  useEffect(() => {
    getSingleConvoData()
  }, [showModal, getSingleConvoData])

  useEffect(() => {
    getMetabaseReport()
  }, [])

  const renderModal = () => {
    return (
      <Modal
        isOpen={showModal}
        ariaHideApp={false}
        className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50 "
        contentLabel="Delete Modal"
      >
        {modalType === 'reply' ?
          <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-full max-w-lg h-auto">
            <div className="cursor-pointer absolute top-2 right-2 text-gray-700 dark:text-gray-300 hover:text-red-500">
              <IoClose size={30} onClick={openModal} />
            </div>
            <Formik
              initialValues={{
                replyMessage: "",
              }}
              onSubmit={async (values, { setSubmitting }) => {
                const formattedHTML = formatTextWithLinks(replyToUser.replyMessage);
                let data = { text: formattedHTML }
                const headers = headerProvider();
                const requestOptions = {
                  method: "POST",
                  headers,
                  body: JSON.stringify(data),
                };
                const response = await replyToConversation(
                  conversation?.id,
                  requestOptions
                );
                if (response) {
                  if (response.success) {
                    showErrorMessage(
                      response.response.title,
                      response.response.description,
                      "success",
                      toast
                    );
                    setShowModal(false);
                  } else {
                    showErrorMessage(
                      "Message was not sent",
                      response.response.errors[0],
                      "error",
                      toast
                    );
                  }
                } else {
                  showErrorMessage(
                    "Server Error",
                    "The server is not responding. Please try later!",
                    "error",
                    toast
                  );
                }
                setReplyToUser({
                  replyMessage: "",
                });
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="p-4">
                  <div className="mb-4">
                    <label
                      className="block text-gray-700 dark:text-gray-300 mb-2"
                      htmlFor="replyMessage"
                    >
                      Reply Message
                    </label>
                    <Field
                      as="textarea"
                      className="block w-full text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md p-2"
                      type="text"
                      name="replyMessage"
                      value={replyToUser.replyMessage}
                      placeholder="Type your message"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      disabled={!replyToUser.replyMessage}
                      className={`w-full h-10 rounded-md ${replyToUser.replyMessage
                        ? "bg-blue-500 text-white"
                        : "bg-blue-300 text-gray-500"
                        }`}
                    >
                      Send
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          :
          modalType === 'metabase' ?
            <div className="relative ml-5 mr-5 overflow-y-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-max mx-auto">
              <div className="flex flex-col items-center h-[500px]">
                <div className="w-full">
                  <p className="text-center font-semibold">Metabase Report</p>
                </div>
                <div className="flex-1 overflow-y-auto w-full px-4 py-2">
                  <RenderData metabaseReportData={metabaseReportData} userMetaScore={userMetaScore} />
                </div>
                <div className="flex justify-center gap-4 w-full mt-4">
                  <button
                    onClick={handleMetabaseReport}
                    type="button"
                    className="py-2 px-6 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-red-300 transition-colors"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            :
            modalType === 'delete' ?
              <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-md mx-auto">
                <div className="flex flex-col items-center">
                  <p className="text-base font-medium text-gray-800 dark:text-gray-100 mb-6">
                    Are you sure you want to delete this conversation?
                  </p>
                  <div className="flex justify-center gap-4">
                    <button
                      onClick={handleDeleteConversation}
                      type="button"
                      className="py-2 px-6 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
                    >
                      Yes
                    </button>
                    <button
                      onClick={handleDeleteAlert}
                      type="button"
                      className="py-2 px-6 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-red-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-red-300 transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
              :
              <div className={`flex flex-col gap-10 relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 ${report?.report ? ' w-11/12' : 'min-w-44'} mx-auto min-h-40`}>
                {report?.report ? <div className='flex flex-col gap-10'>
                  <div>
                    <p className='text-lg'>First Page Report:</p> <p dangerouslySetInnerHTML={{ __html: `${(JSON.parse(report?.report).firstPageReport)}` }} />
                  </div>
                  <div>
                    <p className='text-lg'>Second Page Report:</p> <p dangerouslySetInnerHTML={{ __html: `${(JSON.parse(report?.report).secondPageReport)}` }} />
                  </div>
                </div>
                  :
                  <div>
                    <p>No report is available for this conversation.</p>
                  </div>
                }
                <div className='flex justify-end'>
                  <button
                    onClick={handleReportAlert}
                    type="button"
                    className="py-2 px-6 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-red-300 transition-colors"
                  >
                    Close
                  </button>
                </div>
              </div>}
      </Modal>
    )
  }
  return (
    <div className='flex'>
      <aside className="h-screen sticky top-0 basis-1/5">
        <Sidebar />
      </aside>
      <main className='basis-4/5'>
        <div>
          <nav className=' sticky top-0 flex-no-wrap h-32 flex w-full items-center justify-between bg-[#f4f7fe] py-2 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/10 lg:flex-wrap lg:justify-start lg:py-4'>
            <div className='flex justify-between h-20 w-full'>
              <div className='items-center p-3'>
                <p className='text-base font-bold'>{`Username: ${conversation?.userName}`}</p>
                <p className='text-base font-bold'>{
                  `Status: Active`}
                </p>
              </div>
              <div className='items-center p-3'>
                <p className='text-base font-bold'>Therapist Name</p>
                <p className='text-base font-bold'>{`${conversation?.therapistName}`}</p>
              </div>
              <div className="p-3 flex items-center">
                {conversation?.type === "text" && (
                  <button
                    onClick={openModal}
                    type="button"
                    className="py-2.5 px-5 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-blue dark:hover:bg-gray-700"
                  >
                    Send Reply
                  </button>
                )}
                <button
                  onClick={handleReportAlert}
                  type="button"
                  className="py-2.5 ml-4 px-5 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-blue dark:hover:bg-gray-700"
                >
                  Report
                </button>
                <button
                  onClick={handleMetabaseReport}
                  type="button"
                  className="py-2.5 ml-4 px-5 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-blue dark:hover:bg-gray-700"
                >
                  Metabase Report
                </button>
                {/* <button
                  onClick={handleDeleteAlert}
                  type="button"
                  className="py-2.5 px-5 mx-4 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-red dark:hover:bg-gray-700"
                >
                  Delete
                </button> */}
                {renderModal()}
                <Modal
                  isOpen={deleteAlert}
                  ariaHideApp={false}
                  className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
                  contentLabel="Delete Modal"
                >
                  <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-md mx-auto">
                    <div className="flex flex-col items-center">
                      <p className="text-base font-medium text-gray-800 dark:text-gray-100 mb-6">
                        Are you sure you want to delete this conversation?
                      </p>
                      <div className="flex justify-center gap-4">
                        <button
                          onClick={handleDeleteConversation}
                          type="button"
                          className="py-2 px-6 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
                        >
                          Yes
                        </button>
                        <button
                          onClick={handleDeleteAlert}
                          type="button"
                          className="py-2 px-6 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-red-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:text-red-300 transition-colors"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          </nav>
          {
            loader ?
              <div className='flex justify-center self-center mt-64'>
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
              </div> :
              <div className='mb-10'>
                {
                  conversation?.messages.map((item) => {
                    return <div key={item.id} className={`${item?.therapistId !== null && 'justify-end'} w-full flex`}>
                      <div className={`${item?.therapistId !== null ? 'bg-sky-300' : 'bg-slate-100'} px-4 py-2 max-w-xl rounded-md overflow-hidden shadow-lg mt-5 ml-8 mr-8 `}>
                        <div className="text-gray-700 text-sm" dangerouslySetInnerHTML={{ __html: item?.text }}                    >
                        </div>
                        <div className='flex justify-end'>
                          <p className='mr-4 text-xs font-semibold mt-2'>
                            {new Date(item?.date).toLocaleDateString()}, {new Date(item?.date).toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  })
                }
              </div>
          }
        </div>
      </main>
    </div>
  )
}

export default SingleStudyUser