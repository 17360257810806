import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {showErrorMessage } from "../../../common/CommonFunctions";
import { addLang, createLittleActsActivity, updateActivity } from "../../../services/dataApi/dataProvider";
import { Select,useToast } from "@chakra-ui/react";
import Cookies from 'js-cookie';

const NewActivityForm = ({ onClose, onSubmit, onUpdate, activityObj, allLangActivity }) => {
  const toast = useToast();
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState("");
  const [creadtedFor, setCreatedFor] = useState(new Date());
  const [previewImage, setPreviewImage] = useState(null);
  const [language, setLanguage] = useState('en-in');

  useEffect(() => {
    if (onUpdate) {
      setPreviewImage(activityObj?.url);
      setDescription(activityObj?.description);
      setLanguage(activityObj?.language);
      setCreatedFor(activityObj?.scheduledOn);
    }
  }, [onUpdate,activityObj]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      setPreviewImage(base64String);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const createdOn = new Date();
    const scheduleDate = `${(new Date(creadtedFor)).getFullYear()}-${((new Date(creadtedFor)).getMonth() + 1).toString().padStart(2,'0')}`;
    const token = Cookies.get('token')
    const headers = new Headers();
    headers.append("X-ZUMO-AUTH", token);
    const formdata = new FormData();
    formdata.append("image", image,image.name);
    formdata.append("Description", description);
    formdata.append("createDate", createdOn);
    formdata.append("scheduleDate", scheduleDate);
    const requestOptions = {
      method: "POST",
      headers,
      body: formdata,
      redirect: "follow"
    };
    const response = await createLittleActsActivity(requestOptions);
    if (response) {
      if (response?.success) {
        showErrorMessage('New Activity Added', 'New Activity Added successfully to the list', 'success', toast);
      } else {
        showErrorMessage('Failed', response?.response?.errors[0], 'error', toast);
      }
    }
    else {
      showErrorMessage(
        "Server Error",
        "The server is not responding. Please try later!",
        "error",
        toast
      );
    }
    setImage(null);
    setDescription("");
    onSubmit();
    onClose();

  };

  const handleAddLang = async (data) => {
    const token = Cookies.get('token')
    const headers = new Headers();
    headers.append("X-ZUMO-AUTH", token);
    headers.append("Content-Type", 'application/json');
    const requestOptions = {
      method: "PUT",
      headers,
      body: JSON.stringify(data),
      redirect: "follow"
    };

    const response = await addLang(requestOptions);
    if (response && response.success) {
      showErrorMessage(
        "Updated",
        "Activity description has been updated successfully.",
        "success",
        toast
      );
    } else {
      showErrorMessage(
        response ? "Failed" : "Server Error",
        response ? response?.response?.errors[0] : "The server is not responding. Please try later!",
        "error",
        toast
      );
    }

  }
  const handleUpdateImageAndDate = async () => {
    const token = Cookies.get('token')
    const headers = new Headers();
    headers.append("X-ZUMO-AUTH", token);
    const formdata = new FormData();
    formdata.append("id", activityObj?.id);
    formdata.append("groupId", activityObj?.groupId);    
    if(image) {formdata.append("image", image,image.name)}
    const scheduleDate = `${(new Date(creadtedFor)).getFullYear()}-${((new Date(creadtedFor)).getMonth() + 1).toString().padStart(2,'0')}`;
    formdata.append("scheduleDate", scheduleDate);
    const requestOptions = {
      method: "PUT",
      headers,
      body: formdata,
      redirect: "follow"
    };
    const response = await updateActivity(requestOptions);
    if (response && response.success) {
      showErrorMessage(
        "Updated",
        "Activity data has been updated successfully.",
        "success",
        toast
      );
    } else {
      showErrorMessage(
        response ? "Failed" : "Server Error",
        response ? response?.response?.errors[0] : "The server is not responding. Please try later!",
        "error",
        toast
      );
    }
    onSubmit();
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    const addLanguageData = {
      id: activityObj?.id,
      description: description,
      language: language
    }
    if (activityObj?.description !== description) {
      handleAddLang(addLanguageData);
    }
    if (activityObj?.url !== previewImage || (new Date(activityObj?.scheduledOn)).valueOf() !== (new Date(creadtedFor)).valueOf()) { 
      handleUpdateImageAndDate();
    }
    setImage(null);
    setDescription("");
    onSubmit();
    onClose();
  };

  const handleLangChange = (e) => {
    const selectedLang = e.target.value;
    setLanguage(selectedLang);
    const newDesc = (allLangActivity.filter((item) => {
      return item.language === selectedLang
    }))[0]?.description;
    setDescription(newDesc);
  }
  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `${longMonth} ${fullYear}`;

    return (
      <span
        title={tooltipText}
        className="p-4 mb-2 text-base font-medium text-gray-800 hover:text-blue-600 cursor-pointer rounded-lg transition-colors duration-200 ease-in-out"
      >
        {shortMonth}
      </span>
    );
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 w-full max-w-lg h-auto">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-700 dark:text-gray-300 hover:text-red-500"
        >
          <IoClose size={30} />
        </button>
        {onUpdate ? (
          <h2 className="text-2xl font-semibold mb-4 text-center">
            Edit Activity
          </h2>
        ) : (
          <h2 className="text-2xl font-semibold mb-4 text-center">
            New Activity
          </h2>
        )}

        <form onSubmit={onUpdate ? handleUpdate : handleSubmit} className="pr-2 overflow-y-auto max-h-[80vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200 dark:scrollbar-thumb-gray-600 dark:scrollbar-track-gray-700">
          {previewImage && (
            <div className="flex justify-center h-90">
              {(previewImage === activityObj?.url) ?
                <div
                  className="w-28 h-28 object-cover rounded-lg mb-2 shadow-md"
                  dangerouslySetInnerHTML={{ __html: `${previewImage}` }}
                />
                : <img
                  className="w-28 h-28 object-cover rounded-lg mb-2 shadow-md"
                  src={previewImage}
                  alt={description}
                />}
            </div>
          )}
          <div className="mb-2">
            <label className="block text-gray-700 dark:text-gray-300 mb-2"
              htmlFor="createdFor">
              Created For
            </label>
            <DatePicker
              selected={creadtedFor}
              renderMonthContent={renderMonthContent}
              showMonthYearPicker
              dateFormat="MMM / yyyy"
              onChange={(date) => {
                setCreatedFor(date);
              }}
              className="block w-full text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md p-2"
            />

          </div>
          <div className="mb-2">
            <label
              className="block text-gray-700 dark:text-gray-300 mb-2"
              htmlFor="image"
            >
              Image
            </label>

            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={handleImageChange}
              className="block w-full text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md p-2"
            />
            {!previewImage && (
              <span className="text-red-700">Please provide image!!</span>
            )}
          </div>
          {!onUpdate &&<div className="mb-2">
            <label
              className="block text-gray-700 dark:text-gray-300 mb-2"
              htmlFor="description"
            >
              Language : English
            </label>
             
          </div>} 
          {onUpdate && <div className="mb-2">
            <label
              className="block text-gray-700 dark:text-gray-300 mb-2"
              htmlFor="description"
            >
              Language
            </label>
              <Select onChange={handleLangChange} >
                <option value={'en-in'}>English</option>
                <option value={'kn'}>Kannada</option>
                <option value={'hi'}>Hindi</option>
              </Select>
          </div>}
          <div className="mb-2">
            <label
              className="block text-gray-700 dark:text-gray-300 mb-2"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              id="description"
              value={description ? description : ''}
              onChange={(e) => setDescription(e.target.value)}
              className="block w-full text-gray-700 dark:text-gray-300 border border-gray-300 dark:border-gray-600 rounded-md p-2"

            />

            {!description && (
              <span className="text-red-700">Please provide description or Change to English!!</span>
            )}
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className=" w-full bg-blue-500 text-white px-5 py-3 rounded-md hover:bg-blue-600 disabled:bg-opacity-50"
              disabled={!previewImage || !description}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewActivityForm;
