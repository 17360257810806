import React, { useState } from 'react'
import Sidebar from '../../components/Sidebar'
import Navbar from '../../components/Navbar'
import { FaCirclePlus } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Checkbox, TextField } from '@mui/material';
import { Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useToast } from "@chakra-ui/react";
import { showErrorMessage } from '../../common/CommonFunctions';
import Cookies from 'js-cookie';
import { sendNotification } from '../../services/dataApi/dataProvider';





const Dashboard = () => {
    const Navigate = useNavigate()
    const toast = useToast();
    const [notificationModal, setNotificationModal] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState('');
    const [notificationDesc, setNotificationDesc] = useState('');
    const [disableAllCheckbox, setDisableAllCheckbox] = useState(false);
    const [notifyUser, setNotifyUser] = useState({
        all: false,
        carusers: false,
        normal: false,
    });




    const handleNotification = async () => {
        let selectedTags = [];

        if (notifyUser.carusers) {
            selectedTags.push("carusers");
        }
        if (notifyUser.normal) {
            selectedTags.push("n");
        }
        if (notifyUser.all) {
            selectedTags = [];
        }
        const data = {
            tags: selectedTags,
            title: notificationTitle,
            body: notificationDesc
        }
        console.log(data);
        const token = Cookies.get('token')
        const headers = new Headers();
        headers.append("X-ZUMO-AUTH", token);
        headers.append("Content-Type", 'application/json');
        const requestOptions = {
            method: "POST",
            headers,
            body: JSON.stringify(data),
            redirect: "follow"
        };

        const response = await sendNotification(requestOptions);

        if (response) {
            showErrorMessage(
                "Notification sent",
                "notification sent successfully.",
                "success",
                toast
            );
        } else {
            showErrorMessage(
                response ? "Failed" : "Server Error",
                response ? response?.response?.errors[0] : "The server is not responding. Please try later!",
                "error",
                toast
            );
        }
        resetNotificationForm();
        
    }
    const isSendButtonDisabled = !notificationTitle.trim() || !notificationDesc.trim() || !Object.values(notifyUser).includes(true);

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;

        if (name === "all") {
            // If "All" is checked, disable other checkboxes and select all
            setNotifyUser({ all: checked, carusers: checked, normal: checked });
            setDisableAllCheckbox(checked);
        } else {
            setDisableAllCheckbox(false);
            // If any individual checkbox is checked, uncheck "All" and enable other checkboxes
            setNotifyUser((prevState) => ({
                ...prevState,
                [name]: checked,
                all: false,
            }));
             // Enable other checkboxes 
        }
    };

    const resetNotificationForm =()=>{
        setNotificationModal(false);
        setNotificationDesc('');
        setNotificationTitle('');
        setNotifyUser({ all: false, carusers: false, normal: false });
        setDisableAllCheckbox(false);
        
    }


    return (
        <div className='flex'>
            <aside className="basis-1/5 sticky top-0">
                <Sidebar />
            </aside>
            <div className='flex basis-4/5 flex-col bg-[#f4f7fe]'>
                <div>
                    <Navbar openNotificationForm={setNotificationModal} />
                </div>
                <Modal isOpen={notificationModal} onClose={() => setNotificationModal(false)} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader className=' flex items-center justify-center'>Send Notification</ModalHeader>
                        <ModalBody>
                            <div className="flex flex-col gap-4">
                                <label className="flex items-center gap-2">
                                    <Checkbox
                                        checked={notifyUser.all}
                                        onChange={handleCheckboxChange}
                                        name="all"
                                    />
                                    <span className="text-gray-800 font-medium">All users</span>
                                </label>
                                <label className="flex items-center gap-2">
                                    <Checkbox
                                        checked={notifyUser.carusers}
                                        onChange={handleCheckboxChange}
                                        name="carusers"
                                        disabled={disableAllCheckbox}
                                    />
                                    <span className="text-gray-800 font-medium">CAR users</span>
                                </label>
                                {/* <label className="flex items-center gap-2">
                                    <Checkbox
                                        checked={notifyUser.normal}
                                        onChange={handleCheckboxChange}
                                        name="normal"
                                        disabled={disableAllCheckbox}
                                    />
                                    <span className="text-gray-800 font-medium">Non-CAR users</span>
                                </label> */}
                            </div>
                            <input
                                value={notificationTitle}
                                onChange={(e) => setNotificationTitle(e.target.value)}
                                placeholder="Enter notification title"
                                className="w-full p-2 mt-4 mb-4 border border-gray-300 rounded-md"
                            />
                            <textarea
                                value={notificationDesc}
                                onChange={(e) => setNotificationDesc(e.target.value)}
                                placeholder="Enter notification description"
                                rows={4}
                                className="w-full p-2 border border-gray-300 rounded-md resize-none"
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                colorScheme="green"
                                onClick={handleNotification}
                                disabled={isSendButtonDisabled} // Disable button if fields are incomplete
                                className={`mr-2 ${isSendButtonDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
                            >
                                Send
                            </Button>
                            <Button onClick={resetNotificationForm}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <div className='flex flex-col gap-5 justify-center items-center'>
                    <h1 className='text-3xl font-bold mt-40'>Welcome to Mindnotes Admin Panel</h1>
                    <h1 className='text-3xl font-bold'>{new Date().toDateString()}</h1>
                </div>
                {/* <div className='flex justify-end mt-32'>
                    <div onClick={()=>Navigate('/createLetter')} className='mr-32'>
                        <FaCirclePlus size={40} className='hover:text-blue-600 cursor-pointer' />
                    </div>
                </div> */}

            </div>
        </div>
    )
}

export default Dashboard