import { API_ENDPOINT } from "../config";
import Cookies from 'js-cookie';
import { useLocation, Link } from 'react-router-dom';


export const ApiUrlProvide = (filter, pageNumber, itemsPerPage, field, order) => {
  const queryParams = [];
  queryParams.push(`filter=${encodeURIComponent(JSON.stringify(filter))}`);
  const range = `[${(pageNumber - 1) * itemsPerPage},${(pageNumber - 1) * itemsPerPage + itemsPerPage - 1}]`;
  queryParams.push(`range=${encodeURIComponent(range)}`);
  const sortBy = `[${JSON.stringify(field)},${JSON.stringify(order)}]`
  queryParams.push(`sort=${encodeURIComponent(sortBy)}`);
  return queryParams
}

export const headerProvider = () => {
  const token = Cookies.get('token')
  const headers = {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Request-Method': 'GET',
    "X-ZUMO-AUTH": token
  };
  return headers
}

export const showErrorMessage = (title, description, status, toast) => {
  return toast({
    title,
    description,
    status,
    duration: 3000,
    isClosable: true,
  })

}

export const formatTextWithLinks = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Regular expression to identify phone numbers
  // const phoneRegex = /^-?\d+(\.\d+)?$/
  // const phoneRegex = /(?<!^\D*)\d+(?!\D*$)/g
  const phoneRegex = /\b\d+\b/g;


  const formattedText = text
    .replace(urlRegex, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    })
    .replace(phoneRegex, (phone) => {
      return `<a href="tel:${phone}">${phone}</a>`;
    });

  // Wrap the entire text in a paragraph tag
  return `<p>${formattedText}</p>`;
}