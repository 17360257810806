import React, { useCallback, useEffect, useState } from 'react'
import Sidebar from '../../../components/Sidebar'
import Navbar from '../../../components/Navbar'
import { useNavigate } from 'react-router-dom';
import { getConversationList } from '../../../services/dataApi/dataProvider';
import Pagination from '@mui/material/Pagination';
import { ApiUrlProvide, headerProvider, showErrorMessage } from '../../../common/CommonFunctions';
import { Spinner, useToast } from '@chakra-ui/react'

const ConversationList = () => {
    const Navigate = useNavigate()
    const pageNumConvo = localStorage.getItem('pageNumConvo')
    const sortingFieldConvo = localStorage.getItem('sortingFieldConvo')
    const sortingOrderConvo = localStorage.getItem('sortingOrderConvo')
    const [loader, setLoader] = useState(false)
    const toast = useToast()
    const [conversationList, setConversationList] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(pageNumConvo ? pageNumConvo : 1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [filter, setFilter] = useState({})
    const [sortingConvo, setSortingConvo] = useState({
        field: sortingFieldConvo ? sortingFieldConvo : 'localDateTime',
        order: sortingOrderConvo ? sortingOrderConvo : 'DESC'
    })
    const currentDate = new Date().toLocaleDateString()
    const getAllConversation = useCallback(async () => {
        try {
            setLoader(true)
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, sortingConvo.field, sortingConvo.order)
            const headers = headerProvider()
            const response = await getConversationList(queryParams, headers)
            if (response) {
                setConversationList(response.response)
                setPageCount(Math.ceil(response.totalCount / itemsPerPage))
            }
            else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
            setLoader(false)
        } catch (error) {
            console.log(error);
        }

    }, [filter, pageNumber, itemsPerPage, sortingConvo.field, sortingConvo.order, toast])
    useEffect(() => {
        try {
            getAllConversation()
        } catch (error) {
            console.log(error);
        }
    }, [filter, pageNumber, itemsPerPage, sortingConvo.field, sortingConvo.order, getAllConversation])
    const handleClick = (item) => {
        if (item) {
            Navigate(`/conversation/${item.id}`, { state: item.id })
        }
    }
    const sortByMethod = async (e) => {
        if (e.field) {
            localStorage.setItem('sortingFieldConvo', e.field)
            localStorage.setItem('sortingOrderConvo', e.order)
            setSortingConvo({ field: e.field, order: e.order })
            const queryParams = ApiUrlProvide(filter, pageNumber, itemsPerPage, e.field, e.order)
            const headers = headerProvider()
            const response = await getConversationList(queryParams, headers)
            if (response) {
                setConversationList(response.response)
            }
            else {
                showErrorMessage('Server Error', 'The server is not responding. Please try later!', 'error', toast)
            }
        }
    }
    const handleChange = async (event, value) => {
        if (value) {
            localStorage.setItem('pageNumConvo', value)
            const queryParams = ApiUrlProvide(filter, value, itemsPerPage, sortingConvo.field, sortingConvo.order)
            const headers = headerProvider()
            const response = await getConversationList(queryParams, headers)
            setConversationList(response.response)
        }
    }
    return (
        <div className='h-screen flex'>
            <div className='basis-1/5'>
                <Sidebar />
            </div>
            <div className='flex basis-4/5 flex-col bg-[#f4f7fe]'>
                <div>
                    <Navbar sortByMethod={sortByMethod} />
                </div>
                {
                    loader ?
                        <div className='flex justify-center self-center mt-64'>
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                            />
                        </div>
                        :
                        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-6">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-white uppercase bg-gray-700 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" className="px-6 py-3">
                                            Username
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Therapist
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Type
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Messages count
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Conversation Status
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Expiring on
                                        </th>
                                        <th scope="col" className="px-6 py-3">
                                            Updated Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        conversationList.map((item) => {
                                            return <tr key={item.id} onClick={() => handleClick(item)} className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 cursor-pointer hover:bg-gray-100 w-full">
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold'>
                                                        {item.userName}
                                                    </p>
                                                </td>
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold'>{item.therapistName}</p>
                                                </td>
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold'>{item.type}</p>
                                                </td>
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold text-center'>{item.messagesCount}</p>
                                                </td>
                                                <td className="px-6 py-2">
                                                    {
                                                        !item.isActive ? <p className='text-sm font-semibold text-center bg-green-500 text-white rounded-md p-1'>Completed</p>
                                                            : (new Date()) >= (new Date(item.expiringOn)) && item.isActive ? <p className='text-sm font-semibold text-center bg-red-500 text-white rounded-md p-1'>Expired </p>
                                                                : <p className='text-sm font-semibold text-center bg-blue-500 text-white rounded-md p-1'>In Progress </p>
                                                    }
                                                </td>
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold'>{new Date(item.expiringOn).toLocaleDateString()}</p>
                                                </td>
                                                <td className="px-6 py-2">
                                                    <p className='text-base font-semibold'>{new Date(item.updatedDate).toLocaleDateString()}</p>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                }
                {!loader && <div className='mb-10 flex justify-center h-20 items-center'>
                    <div>
                        <Pagination defaultPage={parseInt(pageNumber)} count={pageCount} color="primary" onChange={handleChange} />
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default ConversationList